<template>
  <th-middle-page-wrapper>
    <template #default>
      <!-- Message -->
      <div :class="{ italic: !isWhiteLabel }">
        <span>{{ text }}</span>
        <span v-if="logoutState === 'logging_out' && loading">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </div>

      <div class="flex justify-end text-xs mt-4">
        <!-- Sign in again -->
        <router-link to="/login" class="text-th-secondary">
          {{ $t('pages.auth.logout.re_signin') }}
        </router-link>
      </div>
    </template>
  </th-middle-page-wrapper>
</template>

<script>
import ThMiddlePageWrapper from '@components/middle-page-wrapper'
import th from '@tillhub/javascript-sdk'
import { mapActions, mapState } from 'vuex'
import { isUnifiedCommerce } from '@/constants'
import { resetPiniaStores } from '@/store/plugins/global-reset'

export default {
  components: {
    ThMiddlePageWrapper
  },

  data() {
    return {
      loading: false,
      timeout: null,
      logoutState: null
    }
  },

  computed: {
    ...mapState('Auth', ['authenticated']),
    text() {
      if (this.logoutState === 'logging_out') {
        return this.$t('pages.auth.logging_out.message')
      }
      if (this.logoutState === 'logged_out') {
        return this.$t('pages.auth.logout.message')
      }
      if (this.logoutState === 'logout_failed') {
        return this.$t('pages.auth.logout_failed.message')
      }
      return ''
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  async mounted() {
    this.loading = true
    this.logoutState = 'logout_failed'
    this.logout()

    try {
      if (this.authenticated) {
        await th.auth.logout()
      }
      this.logoutState = 'logged_out'
    } catch (err) {
      this.logoutState = 'logout_failed'
      this.$logException(err)
    } finally {
      this.loading = false
    }

    th.destroy()
    this.$store.dispatch('resetStore')
    resetPiniaStores()

    if (this.$route.query.redirect) {
      this.$router.replace(this.$route.query.redirect)
      return
    }

    const capturedPath = this.$route.path

    this.timeout = setTimeout(() => {
      if (capturedPath !== this.$route.path) return
      this.$router.replace('/login')
    }, 5000)
  },

  beforeUnmount() {
    clearTimeout(this.timeout)
  },

  methods: {
    ...mapActions('Auth', ['logout'])
  }
}
</script>

<style scoped>
@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.loading span {
  animation: blink 1.4s infinite both;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}
</style>
